<template>
  <div>
    <CreateVm v-model="vm"></CreateVm>
    <div class="create-vm-button">
      <Button icon="plus" @click="createClicked">VM erstellen</Button>
    </div>
  </div>
</template>

<script>
import CreateVm from '@/components/Infrastructure/Vm/CreateVm'
import Button from '@/components/General/Button'
import { mapActions } from 'vuex'

export default {
  components: {
    CreateVm,
    Button
  },
  data () {
    return {
      vm: null
    }
  },
  methods: {
    ...mapActions('infrastructure_vm', ['createVm']),
    async createClicked () {
      if (this.vm.name === '' || this.vm.region === null || this.vm_size === null || this.image === null) {
        alert('Bitte alle Optionen auswählen')
        return
      }
      
      var vm = await this.createVm(this.vm)
      this.$router.push({
        name: 'home.infrastructure.vm.show',
        params: {
          id: vm.id
        }
      })
    }
  }
}
</script>

<style>

</style>