<template>
  <div class="create-vm-container">
    <div class="create-vm-name">
      <div class="create-vm-name-heading">Name</div>
      <Input v-model="vmName" placeholder="VM Name" icon="tag" />
    </div>
    <ChooseRegion :regions="regions" v-model="region" />    
    <ChooseVmSize :vmSizes="vmSizes" v-model="vmSize" />
    <ChooseImage :images="images" v-model="image" />
    <ChooseNetwork :networks="networks" v-model="network" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ChooseImage from '@/components/General/Image/ChooseImage'
import ChooseRegion from '@/components/General/Region/ChooseRegion'
import ChooseVmSize from '@/components/General/VmSize/ChooseVmSize'
import ChooseNetwork from '@/components/Infrastructure/Network/ChooseNetwork'
import Input from '@/components/General/Input'

export default {
  async mounted () {
    if (this.regions.length > 0) {
      this.region = this.regions[0]
    }

    if (this.vmSizes.length > 0) {
      this.vmSize = this.vmSizes[0]
    }

    await this.loadRegions()
    if (this.region === null) this.region = this.regions[0]
    await this.loadVmSizes()
    if (this.vmSize === null) this.vmSize = this.vmSizes[0]
    await this.loadImages()
    await this.loadTenantNetworks()
  },
  props: {
    value: Object
  },
  data () {
    return {
      vmName: '',
      region: null,
      vmSize: null,
      network: null,
      image: null
    }
  },
  components: {
    ChooseRegion,
    ChooseVmSize,
    Input,
    ChooseNetwork,
    ChooseImage
  },
  computed: {
    ...mapState('general_region', ['regions']),
    ...mapState('general_vmSize', ['vmSizes']),
    ...mapState('infrastructure_network', ['networks']),
    ...mapState('infrastructure_image', ['images'])
  },
  methods: {
    ...mapActions('general_region', ['loadRegions']),
    ...mapActions('general_vmSize', ['loadVmSizes']),
    ...mapActions('infrastructure_network', ['loadTenantNetworks']),
    ...mapActions('infrastructure_image', ['loadImages']),
    emitValue () {
      if (this.region !== null && this.vmSize !== null) {
        var toNet = this.network
        if (toNet === null) toNet = []

        this.$emit('input', {
          name: this.vmName,
          region: this.region.id,
          vm_size: this.vmSize.id,
          image: this.image.id,
          networks: toNet
        })
      } 
    }
  },
  watch: {
    vmName: function () { this.emitValue() },
    region: function () { this.emitValue() },
    vmSize: function () { this.emitValue() },
    image: function () { this.emitValue() },
    network: function () { this.emitValue() },
    value: function () {
      if (this.value.region !== this.region.id) {
        this.region = this.regions.filter(x => x.id === this.value.region)
      }

      if (this.value.vm_size !== this.vmSize.id) {
        this.vmSize = this.vmSizes.filter(x => x.id === this.value.vm_size)
      }

      if (this.value.image !== this.image.id) {
        this.image = this.images.filter(x => x.id === this.value.image)
      }

      if (this.name !== this.value.name) {
        this.name = this.value.name
      }

      if (JSON.stringify(this.network) !== JSON.stringify(this.value.networks)) {
        this.network = this.value.networks
      }
    }
  }
}
</script>

<style>

</style>