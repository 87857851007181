<template>
  <ContentSideNavigation>
    <router-link :to="{ name: 'home.infrastructure.network.show.overview' }">Übersicht</router-link>
    <router-link :to="{ name: 'home.infrastructure.network.show.clients' }">Geräte</router-link>
    <router-link :to="{ name: 'home.infrastructure.network.show.settings' }">Einstellungen</router-link>
    <router-link :to="{ name: 'home.infrastructure.network.show.delete' }">Löschen</router-link>
  </ContentSideNavigation>
</template>

<script>
import ContentSideNavigation from '@/components/General/OtherNavigation/ContentSideNavigation'

export default {
  components: {
    ContentSideNavigation
  }
}
</script>

<style>

</style>