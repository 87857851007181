<template>
  <div>
    <TitleNavigation backText="Zurück zu Locations" :title="location.name" :path="{ name: 'home.company.location.list' }"></TitleNavigation>
    <div class="network-content-container">
      <ContentSideNavigation>
        <router-link :to="{ name: 'home.company.location.show.overview' }">Übersicht</router-link>
        <router-link :to="{ name: 'home.company.location.show.settings' }">Einstellungen</router-link>
        <router-link :to="{ name: 'home.company.location.show.delete' }">Löschen</router-link>
    </ContentSideNavigation>
      <transition name="fade" mode="out-in">
        <router-view class="network-content-container-router-view" :location="location"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleNavigation from '@/components/General/OtherNavigation/TitleNavigation'
import ContentSideNavigation from '@/components/General/OtherNavigation/ContentSideNavigation'

export default {
  components: {
    TitleNavigation,
    ContentSideNavigation
  },
  methods: {
    ...mapActions('company_location', ['loadLocation']),
    ...mapActions(['setLoadingPercentage']),
  },
  computed: {
    ...mapGetters('company_location', ['locationById']),
    locationId () {
      return this.$route.params.id
    },
    location () {
      return this.locationById(this.locationId)
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'company_network_load_location', percentage: 30 })
    await this.loadLocation(this.locationId)
    this.setLoadingPercentage({ key: 'company_network_load_location', percentage: 100 })
    this.setLoadingPercentage({ key: 'company_network_load_location', percentage: -1 })
  }
}
</script>

<style>

</style>