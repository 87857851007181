<template>
  <div>
    <CreateEmployee v-model="employee"></CreateEmployee>
    <div class="create-vm-button">
      <Button icon="plus" @click="createClicked">Mitarbeiter erstellen</Button>
    </div>
  </div>
</template>

<script>
import CreateEmployee from '@/components/Company/Employee/CreateEmployee'
import Button from '@/components/General/Button'
import { mapActions } from 'vuex'

export default {
  components: {
    CreateEmployee,
    Button
  },
  data () {
    return {
      employee: null
    }
  },
  methods: {
    ...mapActions('company_employee', ['createEmployee']),
    async createClicked () {
      if (this.employee.firstname === '' || this.employee.lastname === '') {
        alert('Bitte alle Optionen auswählen')
        return
      }
      
      await this.createEmployee(this.employee)
      this.$router.push({
        name: 'home.company.employee'
      })
    }
  }
}
</script>

<style>

</style>