<template>
  <div :class="classes" @click="focusInput">
    <font-awesome-icon v-if="(icon !== '' && icon !== null) || isSearch" class="icon" :icon="inputIcon" />
    <input ref="input" :type="type" @focus="focusChanged(true)" @blur="focusChanged(false)" @input="inputChanged" :value="value" :placeholder="placeholder" @keyup="keyup"/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      focused: false
    }
  },
  props: {
    value: [Number, String, Array],
    placeholder: String,
    isSearch: Boolean,
    icon: {
      type: String,
      default: ''
    },
    type: String
  },
  methods: {
    focusInput () {
      this.$refs.input.focus()
    },
    focusChanged (isFocused) {
      this.focused = isFocused
    },
    inputChanged (data) {
      this.$emit('input', data.srcElement.value)
    },
    keyup (e) {
      this.$emit('keyup', e)
    }
  },
  computed: {
    classes () {
      var base = ['thanos-input']
      if (this.focused) {
        base.push('active')
      }

      return base.join(' ')
    },
    inputIcon () {
      if (this.isSearch) {
        return 'search'
      } else {
        return this.icon
      }
    }
  }
}
</script>

<style>

</style>thanos-input