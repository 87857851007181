<template>
  <div class="choose-vmsize-container">
    <div class="choose-vmsize-heading">
      VM Typ auswählen
    </div>
    <div :class="classes(vmSize.id)" v-for="vmSize in vmSizes" :key="vmSize.id" @click="vmSizeSelected(vmSize)">
      <div class="choose-vmsize-name">{{ vmSize.name }}</div>
      <div class="choose-vmsize-cores">
        <font-awesome-icon icon="microchip" /> {{ vmSize.cpu_cores }} Kerne
      </div>
      <div class="choose-vmsize-ram">
        <font-awesome-icon icon="memory" /> {{ vmSize.ram_size }} MB
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vmSizes: Array,
    value: Object
  },
  data () {
    return {
      selectedVmSize: null
    }
  },
  computed: {
    classes () {
      return (id) => {
        var base = ['choose-vmsize-card']
        if (this.selectedVmSize !== null && this.selectedVmSize.id === id) base.push('active')
        return base.join(' ')
      }
    }
  },
  methods: {
    vmSizeSelected (vmSize) {
      this.$emit('input', vmSize)
      this.selectedVmSize = vmSize
    }
  },
  watch: {
    value: function () {
      this.selectedVmSize = this.value
    }
  }
}
</script>

<style>

</style>