<template>
  <div>
    <ContentHeaderNavigation v-model="data" buttonText="Neue VM" buttonIcon="plus" toggleText="Gruppenansicht" @addClicked="openNewVm" />
    <CardContainer :addable="true" addText="Neue Gruppe" @addClicked="openNewVm">
      <Card v-for="vm in displayVms" :key="vm.id" @click="cardClicked(vm.id)">
        <font-awesome-icon icon="desktop" style="position: absolute; color: #aaa; left: -20px" v-if="vm.vm_type === '5888a38a-8fa3-471a-beca-590e8f516a11'" />
        {{ vm.name }}
      </Card>
    </CardContainer>
  </div>
</template>

<script>
import { mapActions, mapState} from 'vuex'
import ContentHeaderNavigation from '@/components/General/OtherNavigation/ContentHeaderNavigation'
import Card from '@/components/General/Card/Card'
import CardContainer from '@/components/General/Card/CardContainer'

export default {
  data () {
    return {
      data: {
        searchData: '',
        toggleData: false
      }
    }
  },
  computed: {
    ...mapState('infrastructure_vm', ['vms']),
    displayVms () {
      if (this.data.searchData && this.data.searchData.length > 0) {
        var toSearch = this.data.searchData.toLowerCase()
        return this.vms.filter(x => x.name.toLowerCase().indexOf(toSearch) >= 0)
      } else {
        return this.vms
      }
      
    }
  },
  components: {
    ContentHeaderNavigation,
    Card,
    CardContainer
  },
  methods: {
    ...mapActions('infrastructure_vm', ['loadTenantVms']),
    ...mapActions(['setLoadingPercentage']),
    cardClicked (id) {
      this.$router.push({ name: 'home.infrastructure.vm.show', params: { id } })
    },
    openNewVm () {
      this.$router.push({
        name: 'home.infrastructure.vm.create'
      })
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: 30 })
    await this.loadTenantVms()
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: 100 })
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: -1 })
  }
}
</script>

<style>

</style>