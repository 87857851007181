<template>
  <div :class="classes" @click="cardClicked">
    <div class="thanos-card-icon-container">
      <div class="icon" v-if="addable">
        <font-awesome-icon icon="plus" />
      </div>
      <div class="thanos-card-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addable: Boolean,
    dense: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cardClicked (e) {
      this.$emit('click', e)
    }
  },
  computed: {
    classes () {
      var base = ['thanos-card']

      if (this.addable) {
        base.push('addable')
      }

      if (this.dense) {
        base.push('dense')
      }

      return base.join(' ')
    }
  }
}
</script>

<style>

</style>