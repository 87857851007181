<template>
  <div class="create-vm-container">
    <div class="create-vm-name">
      <div class="create-vm-name-heading">Name</div>
      <Input v-model="networkName" placeholder="Network Name" icon="tag" />
    </div>
    <div class="create-vm-name">
      <div class="create-vm-name-heading">Netzwerk</div>
      <Input v-model="network" placeholder="Netzwerk" icon="tag" />
      <span style="width: 100%; text-align: left; color: #aaa; margin-bottom: 20px;">X.X.X.X/XX (bspw. 10.0.0.0/24)</span>
    </div>
    <ChooseRegion :regions="regions" v-model="region" />    
    <div class="settings-container" style="margin-top: 20px;">
      <Toggle style="justify-content: left; margin-bottom: 20px;" v-model="dhcpEnabled">DHCP aktiviert</Toggle>
      <Toggle style="justify-content: left; margin-bottom: 20px;" v-model="internetEnabled">Internetzugriff aktiviert</Toggle>
    </div>
  </div>
</template>

<script>
import ip from 'ip'
import { mapActions, mapState } from 'vuex'
import ChooseRegion from '@/components/General/Region/ChooseRegion'
import Input from '@/components/General/Input'
import Toggle from '@/components/General/Toggle'

export default {
  async mounted () {
    if (this.regions.length > 0) {
      this.region = this.regions[0]
    }

    await this.loadRegions()
    if (this.region === null) this.region = this.regions[0]
  },
  props: {
    value: Object
  },
  data () {
    return {
      networkName: '',
      region: null,
      dhcpEnabled: false,
      internetEnabled: false,
      network: ''
    }
  },
  components: {
    ChooseRegion,
    Input,
    Toggle
  },
  computed: {
    ...mapState('general_region', ['regions']),
    networkValid () {
      var valid = false
      if (this.network.indexOf('/') >= 0) {
        var network = this.network.split('/')[0]
        var cidr = parseInt(this.network.split('/')[1])
        if (ip.isV4Format(network) && ip.isPrivate(network)) {
          if (cidr > 16 && cidr <= 32) {
            valid = true
          }
        }
      }
      return valid
    }
  },
  methods: {
    ...mapActions('general_region', ['loadRegions']),
    emitValue () {
      if (this.region !== null) {

        this.$emit('input', {
          name: this.networkName,
          region: this.region.id,
          dhcpEnabled: this.dhcpEnabled,
          internetEnabled: this.internetEnabled,
          network: this.network
        })
      } 
    }
  },
  watch: {
    networkName: function () { this.emitValue() },
    region: function () { this.emitValue() },
    dhcpEnabled: function () { this.emitValue() },
    internetEnabled: function () { this.emitValue() },
    network: function () { 
      if (this.networkValid) {
        var cidr = this.network.split('/')[1]
        var network = this.network.split('/')[0]
        var subnet = ip.cidrSubnet(this.network)

        console.log(subnet.networkAddress)
        console.log(network)
        if (subnet.networkAddress !== network) {
          this.network = subnet.networkAddress + '/' + cidr
        }
      }
      this.emitValue() 
    },
    value: function () {
      if (this.value.region !== this.region.id) {
        this.region = this.regions.filter(x => x.id === this.value.region)
      }

      if (this.networkName !== this.value.name) {
        this.networkName = this.value.name
      }

      if (this.network !== this.value.network) {
        this.network = this.value.network
      }

      if (this.dhcpEnabled !== this.value.dhcpEnabled) {
        this.dhcpEnabled = this.value.dhcpEnabled
      }

      if (this.internetEnabled !== this.value.internetEnabled) {
        this.internetEnabled = this.value.internetEnabled
      }
    }
  }
}
</script>

<style>

</style>