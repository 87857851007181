<template>
  <div style="min-height: 100vh;">
    <MainNavigation>
      <MainTab :to="{ name: 'electron.clients' }">Clients</MainTab>
      <template v-slot:settings>
        <font-awesome-icon style="padding: 0px 40px; font-size: 20px" icon="user" />
      </template>

      <template v-slot:logo>
        <img src="/img/Logo.png" />
      </template>
    </MainNavigation>
    <div class="loading-slider" :style="loadingSliderStyle"></div>
    
    <router-view></router-view>
    <div style="position: absolute; bottom: 0px; padding: 20px; right: 0px; color: #aaa">
      Copyright 2022 - RAIT Systems GmbH
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainNavigation from '@/components/General/MainNavigation/MainNavigation.vue'
import MainTab from '@/components/General/MainNavigation/MainTab.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'Home',
  components: {
    MainNavigation,
    MainTab
  },
  async beforeRouteEnter (to, from, next) {
    if (!store.getters.isLoggedIn) {
      next({ name: 'login' })
    } else {
      next() 
    }
  },
  mounted () {
    this.initWsIfNeeded()
  },
  methods: {
    ...mapActions(['initWsIfNeeded'])
  },
  computed: {
    ...mapState(['directus']),
    ...mapGetters(['loadingPercentage', 'isLoggedIn']),
    ...mapGetters('infrastructure_client', ['clientById']),
    loadingSliderStyle () {
      return {
        width: this.loadingPercentage + '%'
      }
    }
  }
}
</script>
