<template>
  <div>
    <CreateNetwork v-model="network"></CreateNetwork>
    <div class="create-vm-button">
      <Button icon="plus" @click="createClicked">Netzwerk erstellen</Button>
    </div>
  </div>
</template>

<script>
import CreateNetwork from '@/components/Infrastructure/Network/CreateNetwork'
import Button from '@/components/General/Button'
import { mapActions } from 'vuex'

export default {
  components: {
    CreateNetwork,
    Button
  },
  data () {
    return {
      network: null
    }
  },
  methods: {
    ...mapActions('infrastructure_network', ['createNetwork']),
    async createClicked () {
      if (this.network.name === '' || this.network.region === null || this.network.network === null) {
        alert('Bitte alle Optionen auswählen')
        return
      }
      
      var network = await this.createNetwork(this.network)
      this.$router.push({
        name: 'home.infrastructure.network.show',
        params: {
          id: network.id
        }
      })
    }
  }
}
</script>

<style>

</style>