<template>
  <div>
    <div style="width: 100%">
      <select v-model="selectedInterval">
        <option v-for="(value, key) in intervalOptions" :key="key" :value="key">{{ value }}</option>
      </select>
    </div>
    <apexchart height="300" width="1000" type="area" :options="options" :series="cpuSeries"></apexchart>
    <apexchart height="300" width="1000" type="area" :options="options" :series="ramSeries"></apexchart>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.selectedInterval = 'hour'
  },
  data () {
    return {
      intervalOptions: { 
        'hour': '1 Stunde',
        'day': '1 Tag',
        'week': '1 Woche',
        'month': '1 Monat',
        'year': '1 Jahr'
      },
      selectedInterval: null,
      options: {
        chart: {
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          labels: {
            formatter (val) {
              return Math.round(val * 1000) / 10 + '%'
            }
          },
          max: 1,
          min: 0
        }
      },
      cpuSeries: [],
      ramSeries: []
    }
  }, 
  methods: {
    ...mapActions(['wsSendAndWait']),
    async loadData() {
      this.cpuSeries = []
      this.ramSeries = []
      var data = await this.wsSendAndWait({
        type: 'cmd',
        action: 'vm_getStats',
        data: {
            vmId: this.vmId,
            interval: this.selectedInterval
        }
      })

      var cpuData = []
      var ramData = []
      data.data.forEach(usage => {
        cpuData.push([usage.time, usage.cpu])
        ramData.push([usage.time, (usage.mem / usage.maxmem)])
      });
      console.log(cpuData)


      this.cpuSeries.push({
        name: 'CPU',
        data: cpuData
      })

      this.ramSeries.push({
        name: 'RAM',
        data: ramData
      })
    }
  },
  computed: {
    vmId () {
      return this.$route.params.id
    }
  },
  watch: {
    selectedInterval () {
      this.loadData()
    }
  }
}
</script>

<style>

</style>