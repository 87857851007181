<template>
  <div class="content-header-navigation">
    <div class="button-container">
      <Button :icon="buttonIcon" @click="addClicked">{{ buttonText }}</Button>
    </div>
    <div class="search-container">
      <Input v-model="searchData" :placeholder="searchPlaceholder" :isSearch="true"></Input>
    </div>
    <div class="settings-container">
      <Toggle v-model="toggleData" v-if="false">{{ toggleText }}</Toggle>
    </div>
  </div>
</template>

<script>
import Button from '@/components/General/Button'
import Input from '@/components/General/Input'
import Toggle from '@/components/General/Toggle'

export default {
  components: {
    Button,
    Input,
    Toggle
  },
  props: {
    searchPlaceholder: {
      type: String,
      default: 'Search'
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    toggleText: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {
        return {
          toggleData: false,
          searchData: ''
        }
      }
    }
  },
  data () {
    return {
      toggleData: false,
      searchData: ''
    }
  },
  methods: {
    emitValue () {
      this.$emit('input', {
        toggleData: this.toggleData,
        searchData: this.searchData,
      })
    },
    addClicked (e) {
      this.$emit('addClicked', e)
    }
  },
  watch: {
    value: () => {
      this.toggleData = this.value.toggleData
      this.searchData = this.value.searchData
    },
    toggleData: {
      handler: 'emitValue'
    },
    searchData: {
      handler: 'emitValue'
    }
  }
}
</script>

<style>

</style>