<template>
  <div>
    <TitleNavigation backText="Zurück zu Netzwerken" :title="network.name" :path="{ name: 'home.infrastructure.network.list' }"></TitleNavigation>
    <div class="network-content-container">
      <NetworkSideNavigation />
      <transition name="fade" mode="out-in">
        <router-view class="network-content-container-router-view" :network="network"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleNavigation from '@/components/General/OtherNavigation/TitleNavigation'
import NetworkSideNavigation from '@/components/Infrastructure/NetworkSideNavigation'

export default {
  components: {
    TitleNavigation,
    NetworkSideNavigation
  },
  methods: {
    ...mapActions('infrastructure_network', ['loadNetwork']),
    ...mapActions(['setLoadingPercentage']),
  },
  computed: {
    ...mapGetters('infrastructure_network', ['networkById']),
    networkId () {
      return this.$route.params.id
    },
    network () {
      return this.networkById(this.networkId)
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: 30 })
    await this.loadNetwork(this.networkId)
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: 100 })
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: -1 })
  }
}
</script>

<style>

</style>