<template>
  <div>
      <div class="card-vm" v-for="vm in network.vms" :key="vm.id" @click="cardClicked(vm)">
        <div class="name">{{ vm.name }}</div>
        <div class="power_state" :style="power_style(vm.state)"></div>
        <div class="size">{{ vm.vm_size.name }}</div>
      </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    network: Object
  },
  data () {
    return {
      colorMap: {
        '929e316b-ed5c-4c5c-b6bd-b859bdf6260c': 'black',
        'b5d17141-b740-4f40-a26c-6f9c962d05a3': 'yellow',
        'e83353fb-cf23-48de-ba9b-0a4de19bfca0': 'red',
        '5b41e044-46f8-4c90-b9e3-8d91bf387094': 'green'
      },
      vmIds: []
    }
  },
  mounted () {
    this.setVmsToLoad()
  },
  beforeDestroy () {
    console.log('removed vms')
    this.addVmsToStateLoad({ vms: this.vmIds, load: false })
  },
  computed : {
    power_style () {
      return (state) => {
        var color = 'gray'
        if (this.colorMap[state] !== undefined) {
          color = this.colorMap[state]
        }

        return {
          background: color
        }
      }
    }
  },
  methods: {
    ...mapActions('infrastructure_client', ['getClientIdByVmId']),
    ...mapActions('infrastructure_vm', ['addVmsToStateLoad']),
    async cardClicked (vm) {
      if (vm.vm_type === 'vdi') {
        var clientId = await this.getClientIdByVmId(vm.id)
        this.$router.push({ name: 'home.infrastructure.client.show', params: { id: clientId }})
      } else {
        this.$router.push({ name: 'home.infrastructure.vm.show', params: { id: vm.id }})
      }
    },
    setVmsToLoad () {
      this.addVmsToStateLoad({ vms: this.vmIds, load: false })
      this.vmIds = this.network.vms.map(x => x.id)
      this.addVmsToStateLoad({ vms: this.vmIds, load: true })
    }
  },
  watch: {
    network: {
      deep: true,
      handler (to, from) {
        if (JSON.stringify(to.vms) !== JSON.stringify(from.vms)) {
          this.setVmsToLoad()
        }
      }
    }
  }
}
</script>

<style>
.card-vm {
  padding: 15px 10px;
  background: white;
  width: calc(100% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 4px;
  color: rgb(56, 56, 56);
  box-shadow: 0 1px 1px rgba(0,0,0,0.15);
  align-items: center;
}

.card-vm > .size {
  width: 30%
}

.card-vm > .name {
  width: 10%
}

.card-vm:hover {
  background: #fafafa;
  box-shadow: 2px 3px 10px rgba(0,0,0,0.1);
  cursor: pointer;
}

.card-vm > .power_state {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
</style>