<template>
  <div class="choose-client-container">
    <CardContainer :addable="false">
      <Card v-for="client in clients" :key="client.id" @click="cardClicked(client.id)">
        {{ client.name }}
      </Card>
    </CardContainer>
  </div>
</template>

<script>
import CardContainer from '@/components/General/Card/CardContainer'
import Card from '@/components/General/Card/Card'
import { mapActions, mapGetters } from 'vuex'
const fs = require('fs');
const { spawn } = require('child_process');
const {ipcRenderer} = require('electron');


export default {
  async mounted () {
    this.clientIds = await this.getUserClients()
    this.clientIds.forEach(async (x) => {
      await this.loadClient(x)
    })
  },
  components: {
    Card,
    CardContainer
  },
  data () {
    return {
      clientIds: [],
      rvPath: ''
    }
  },
  methods: {
    ...mapActions('infrastructure_client', ['getUserClients', 'loadClient']),
    ...mapActions(['wsSendAndWait', 'setLoadingPercentage']),
    async cardClicked (id) {
      await this.downloadViewer()
      var client = this.clientById(id)

      var data = await this.wsSendAndWait({
          type: 'cmd',
          action: 'vm_startSpice',
          data: {
            vmId: client.vm
          }
      })
      console.log(data)

      var lines = [
          '[virt-viewer]',
          ...data.data.lines
      ]

      var spiceFile = 'C:\\temp\\spice.vv'
      fs.writeFileSync(spiceFile, lines.join('\n'))
      spawn(this.rvPath, [spiceFile, '-f'])

    },
    remoteViewerExists () {
      console.log(this.rvPath)
      console.log(fs.existsSync(this.rvPath))
      return fs.existsSync(this.rvPath)
    },
    downloadViewer () {
      return new Promise ((res) => {
        ipcRenderer.send("download",);
        ipcRenderer.on('download-progress', (e, data) => {
          var { progress, path } = data
          this.rvPath = path
          this.setLoadingPercentage({ key: 'download_remote_viewer', percentage: progress * 100 })

          if (progress === 1) {
            this.setLoadingPercentage({ key: 'download_remote_viewer', percentage: 0 })
            res()
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters('infrastructure_client', ['clientById']),
    clients () {
      var clients = []
      this.clientIds.forEach(x => {
        clients.push(this.clientById(x))
      })
      console.log('Dies ist ein Test')
      console.log(clients)
      return clients
    }
  }
}
</script>

<style>

</style>