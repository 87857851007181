<template>
  <div class="choose-image-container">
    <div class="choose-image-heading">
      Client Typ auswählen
    </div>
    <div :class="classes(clientType.id)" v-for="clientType in clientTypes" :key="clientType.id" @click="clientTypeSelected(clientType)">
      <div class="choose-image-icon">
        <img :src="imgPath(clientType.icon)" />
      </div>
      <div class="choose-image-name">{{ clientType.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clientTypes: Array,
    value: Object
  },
  data () {
    return {
      selectedClientType: null
    }
  },
  computed: {
    classes () {
      return (id) => {
        var base = ['choose-image-card']
        if (this.selectedClientType !== null && this.selectedClientType.id === id) base.push('active')
        return base.join(' ')
      }
    },
    imgPath () {
      return (id) => {
        return process.env.VUE_APP_DIRECTUS_URL + '/assets/' + id
      }
    }
  },
  methods: {
    clientTypeSelected (clientType) {
      this.$emit('input', clientType)
      this.selectedClientType = clientType
    }
  },
  watch: {
    value: function () {
      this.selectedClientType = this.value
    }
  }
}
</script>

<style>

</style>