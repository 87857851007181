<template>
  <div class="create-vm-container">
    <div class="create-vm-name">
      <div class="create-vm-name-heading">Vorname</div>
      <Input v-model="firstname" placeholder="Vorname" icon="tag" />
    </div>
    <div class="create-vm-name">
      <div class="create-vm-name-heading">Nachname</div>
      <Input v-model="lastname" placeholder="Nachname" icon="tag" />
    </div>
    <ChooseLocation :locations="locations" v-model="location" />
    <ChooseEmployee :employees="employees" v-model="manager" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Input from '@/components/General/Input'
import ChooseLocation from '@/components/Company/Location/ChooseLocation'
import ChooseEmployee from '@/components/Company/Employee/ChooseEmployee'

export default {
  components: {
    Input,
    ChooseLocation,
    ChooseEmployee
  },
  async mounted () {
    await this.loadTenantLocations()
    this.location = this.locations[0].id

    await this.loadTenantEmployees()
    this.manager = this.employees[0].id
  },
  props: {
    value: Object
  },
  data () {
    return {
      firstname: '',
      lastname: '',
      location: null,
      manager: null
    }
  },
  computed: {
    ...mapState('company_location', ['locations']),
    ...mapState('company_employee', ['employees'])
  },
  methods: {
    ...mapActions('company_location', ['loadTenantLocations']),
    ...mapActions('company_employee', ['loadTenantEmployees']),
    emitValue () {
      if (this.firstname !== '' && this.lastname !== '') {
        this.$emit('input', {
          firstname: this.firstname,
          lastname: this.lastname,
          primary_location: this.location,
          manager: this.manager
        })
      } 
    }
  },
  watch: {
    firstname: function () { this.emitValue() },
    lastname: function () { this.emitValue() },
    location: function () { this.emitValue() },
    manager: function () { this.emitValue() },
    value: function () {
      if (this.firstname !== this.value.firstname) {
        this.firstname = this.value.firstname
      }

      if (this.lastname !== this.value.lastname) {
        this.lastname = this.value.lastname
      }

      if (this.location !== this.value.primary_location) {
        this.location = this.value.primary_location
      }

      if (this.manager !== this.value.manager) {
        this.manager = this.value.manager
      }
    }
  }
}
</script>

<style>

</style>