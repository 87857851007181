<template>
  <div class="thanos-button" @click="clicked">
    <font-awesome-icon v-if="icon !== ''" :icon="icon" class="thanos-button-icon" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked (e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style>

</style>