<template>
  <div>
    <TitleNavigation backText="Zurück zu Employees" :title="employee.firstname + ' ' + employee.lastname" :path="{ name: 'home.company.employee.list' }"></TitleNavigation>
    <div class="network-content-container">
      <ContentSideNavigation>
        <router-link :to="{ name: 'home.company.employee.show.overview' }">Übersicht</router-link>
        <router-link :to="{ name: 'home.company.employee.show.settings' }">Einstellungen</router-link>
        <router-link :to="{ name: 'home.company.employee.show.delete' }">Löschen</router-link>
    </ContentSideNavigation>
      <transition name="fade" mode="out-in">
        <router-view class="network-content-container-router-view" :employee="employee"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleNavigation from '@/components/General/OtherNavigation/TitleNavigation'
import ContentSideNavigation from '@/components/General/OtherNavigation/ContentSideNavigation'

export default {
  components: {
    TitleNavigation,
    ContentSideNavigation
  },
  methods: {
    ...mapActions('company_employee', ['loadEmployee']),
    ...mapActions(['setLoadingPercentage']),
  },
  computed: {
    ...mapGetters('company_employee', ['employeeById']),
    employeeId () {
      return this.$route.params.id
    },
    employee () {
      return this.employeeById(this.employeeId)
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'company_network_load_employee', percentage: 30 })
    await this.loadEmployee(this.employeeId)
    this.setLoadingPercentage({ key: 'company_network_load_employee', percentage: 100 })
    this.setLoadingPercentage({ key: 'company_network_load_employee', percentage: -1 })
  }
}
</script>

<style>

</style>