<template>
  <div>
    <Input placeholder="VM Name" v-model="vmname" icon=""/>
    <Button @click="deleteVm" style="margin-left: 20px" :disabled="vmname !== vm.name">Löschen</Button>
  </div>
</template>

<script>
import Input from '@/components/General/Input'
import Button from '@/components/General/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Input,
    Button
  },
  data () {
    return {
      vmname: ''
    }
  },
  computed: {
    ...mapGetters('infrastructure_vm', ['vmById']),
    vmId () {
      return this.$route.params.id
    },
    vm () {
      return this.vmById(this.vmId)
    }
  },
  methods: {
    ...mapActions(['setLoadingPercentage']),
    ...mapActions('infrastructure_vm', ['updateVm']),
    async deleteVm () {
      if (this.vmname === this.vm.name) {
        this.setLoadingPercentage({ key: 'infrastructure_network_delete_vm', percentage: 30 })

        await this.updateVm({
          id: this.vmId,
          state: 'ae00ba28-1990-4787-885e-0708c06737ae'
        })
        this.vmname = ''

        this.setLoadingPercentage({ key: 'infrastructure_network_delete_vm', percentage: 100 })
        this.setLoadingPercentage({ key: 'infrastructure_network_delete_vm', percentage: -1 })
      } else {
        alert('VM Name korrekt angeben.')
      }
    }
  }
}
</script>

<style>

</style>