<template>
  <div>
    <ContentHeaderNavigation v-model="data" buttonText="Neue Gruppe" buttonIcon="plus" toggleText="Gruppenansicht" @addClicked="newEmployee"/>
    
    <CardContainer :addable="true" addText="Neue Gruppe" @addClicked="newEmployee">
      <Card v-for="employee in displayEmployees" :key="employee.id" @click="cardClicked(employee.id)">
        {{ employee.firstname }} {{ employee.lastname }}
      </Card>
    </CardContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContentHeaderNavigation from '@/components/General/OtherNavigation/ContentHeaderNavigation'
import Card from '@/components/General/Card/Card'
import CardContainer from '@/components/General/Card/CardContainer'

export default {
  data () {
    return {
      data: {
        searchData: '',
        toggleData: false
      }
    }
  },
  components: {
    ContentHeaderNavigation,
    Card,
    CardContainer
  },
  methods: {
    ...mapActions('company_employee', ['loadTenantEmployees']),
    ...mapActions(['setLoadingPercentage']),
    cardClicked (id) {
      this.$router.push({ name: 'home.company.employee.show', params: { id } })
    },
    newEmployee () {
      this.$router.push({ name: 'home.company.employee.create' })
    }
  },
  computed: {
    ...mapState('company_employee', ['employees']),
    displayEmployees () {
      if (this.data.searchData && this.data.searchData.length > 0) {
        var toSearch = this.data.searchData.toLowerCase()
        return this.employees.filter(x => x.firstname.toLowerCase().indexOf(toSearch) >= 0 || x.lastname.toLowerCase().indexOf(toSearch) >= 0)
      } else {
        return this.employees
      }
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'company_employee_load_employees', percentage: 30 })
    await this.loadTenantEmployees()
    this.setLoadingPercentage({ key: 'company_employee_load_employees', percentage: 100 })
    this.setLoadingPercentage({ key: 'company_employee_load_employees', percentage: -1 })
  }
}
</script>

<style>

.container {
  display: flex;
  padding-top: 40px;
  flex-wrap: wrap;
}

.container > .card {
  padding: 40px 10px;
  background: white;
  width: calc(16.666% - 40px);
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 2px 2px 1px 1px rgba(0, 0, 0, 0.15);
}

.container > .add-card {
  box-shadow: none;
  border: 3px dotted rgba(0, 0, 0, 0.2); 
  color: #3f88c5;
}

.container > .add-card:hover {
  border: 3px dotted #3f88c5;
  cursor: pointer; 
}

.container > .add-card > .icon {
  padding-bottom: 5px
}

</style>