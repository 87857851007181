<template>
  <div class="choose-region-container">
    <div class="choose-region-heading">
      Mitarbeiter auswählen
    </div>
    <select v-model="selectedEmployee">
      <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ employee.firstname }} {{ employee.lastname }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    employees: Array,
    value: String
  },
  data () {
    return {
      selectedEmployee: null
    }
  },
  watch: {
    value: function () {
      this.selectedEmployee = this.value
    },
    selectedEmployee () {
      this.$emit('input', this.selectedEmployee)
    }
  }
}
</script>

<style>

</style>