<template>
  <div>
      <div class="card" style="text-align: center">
        <span style="font-size: 40px; font-weight: bold; color: #3f88c5;">{{ network.vms.length }}</span><br /><br />
        <span style="color: #999">Geräte</span> 
      </div>
      <div class="card" style="text-align: center">
        <span style="font-size: 40px; font-weight: bold; color: #3f88c5;">{{ 255 - network.vms.length }}</span><br /><br />
        <span style="color: #999">IP-Addressen Frei</span> 
      </div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
      <div class="card" style="width: 100%">Dies ist ein Test</div>
  </div>
</template>

<script>
export default {
  props: {
    network: Object
  }
}
</script>

<style>
.card {
  padding: 40px 10px;
  background: white;
  width: calc(15% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 4px;
  color: rgb(56, 56, 56);
  box-shadow: 0 1px 1px rgba(0,0,0,0.15)
}
</style>