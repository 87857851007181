<template>
  <div>
      <ChooseEmployee :employees="employees" v-model="employee" />
      <Button @click="saveEmployee" style="margin-left: 20px">Speichern</Button>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import ChooseEmployee from '@/components/Company/Employee/ChooseEmployee'
import Button from '@/components/General/Button'
export default {
  async mounted () {
    await this.loadTenantEmployees()
    console.log(this.client)
    this.employee = this.client.employee
  },
  data () {
    return {
      employee: null
    }
  },
  components: {
    ChooseEmployee,
    Button
  },
  computed: {
    ...mapState('company_employee', ['employees']),
    ...mapGetters('infrastructure_client', ['clientById']),
    clientId () {
      return this.$route.params.id
    },
    client () {
      return this.clientById(this.clientId)
    }
  },
  methods: {
    ...mapActions('company_employee', ['loadTenantEmployees']),
    ...mapActions('infrastructure_client', ['updateClient']),
    ...mapActions(['setLoadingPercentage']),
    async saveEmployee () {
      this.setLoadingPercentage({ key: 'update_client_employee', percentage: 30 })
      await this.updateClient({
        id: this.clientId,
        employee: this.employee
      })
      this.setLoadingPercentage({ key: 'update_client_employee', percentage: 100 })
      this.setLoadingPercentage({ key: 'update_client_employee', percentage: -1 })
    }
  },
}
</script>

<style>

</style>