<template>
    <div :style="consoleStyle">
        <div style="width: 100%; display: flex; padding-bottom: 20px;">
            <Button @click="openFullScreen" :disabled="!showConsole">FullScreen</Button>
        </div>
        <div :style="consoleStyle" v-if="showConsole" ref="consoleDiv"></div>
        <Button @click="sendCtrlAltDel" v-if="isFullScreen" style="position: absolute; top: 10px; left: 10px;">Ctrl + Alt + Del</Button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RFB from '@novnc/novnc/core/rfb';
import Button from '@/components/General/Button'

export default {
    components: {
        Button
    },
    data() {
    return {
            rfb: null
        };
    },
    async mounted () {
        var timeout = 0
        if (this.isFullScreen) timeout = 1000
        setTimeout(this.init, timeout)
    },
    destroyed () {
        this.rfb._sock.close()
        console.log('TEST2')
        console.log(this.rfb)
    },
    methods: {
        ...mapActions(['wsSendAndWait']),
        openFullScreen () {
            var route = this.$router.resolve({ name: this.$route.name, query: { fullScreen: true }})
            var newUrl = window.location.protocol + '//' + window.location.host + route.href
            window.open(newUrl, this.vmId + " FullScreen", "width=600,height=400,status=yes,scrollbars=yes,resizable=yes");
        },
        async init () {
            var data = await this.wsSendAndWait({
                type: 'cmd',
                action: 'vm_startVncWs',
                data: {
                    vmId: this.vmId
                }
            })
            var websocketId = data.data
            
            this.rfb = new RFB(this.$refs.consoleDiv, process.env.VUE_APP_WS_URL + "/novnc/" + websocketId);
        },
        sendCtrlAltDel () {
            this.rfb.sendCtrlAltDel()
        }
    },
    computed: {
        ...mapGetters('infrastructure_vm', ['vmById']),
        vmId () {
            return this.$route.params.id
        },
        vm () {
            return this.vmById(this.vmId)
        },
        showConsole () {
            return this.vm.state === '5b41e044-46f8-4c90-b9e3-8d91bf387094'
        },
        isFullScreen () {
            console.log('1-------')
            console.log(this.$route.query)
            console.log(this.$route.query.fullScreen === "true")
            return this.$route.query.fullScreen === "true"
        },
        consoleStyle () {
            if (this.isFullScreen) {
                return {
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    background: 'black'
                }
            } else {
                return {}
            }
        }
    }
}
</script>

<style>

</style>