<template>
  <div class="login-page">
    <Card class="login-card">
      <div class="login-title">
        Einloggen
      </div>
      <div class="login-input">
        <Input v-model="username" placeholder="Username" icon="user" />
        <Input v-model="password" placeholder="Passwort" type="password" icon="key" @keyup.enter="initLogin" />
        <Button icon="lock" @click="initLogin">Einloggen</Button>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/General/Card/Card'
import Input from '@/components/General/Input'
import Button from '@/components/General/Button'
import { mapActions } from 'vuex'

const isElectron = require("is-electron");

export default {
  components: {
    Card,
    Input,
    Button
  },
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(['login']),
    async initLogin () {
      await this.login({
        email: this.username,
        password: this.password
      })

      if(isElectron()){
        this.$router.push('electron')
      }else{
        this.$router.push('home')
      }
      
    }
  }
}
</script>

<style>

</style>