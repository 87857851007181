<template>
  <div>
    <div class="network-content-container">
      <ContentSideNavigation>
        <router-link :to="{ name: 'home.company.settings.general' }">Allgemein</router-link>
        <router-link :to="{ name: 'home.company.settings.payment' }">Abrechnung</router-link>
      </ContentSideNavigation>
      <transition name="fade" mode="out-in">
        <router-view class="network-content-container-router-view" ></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import ContentSideNavigation from '@/components/General/OtherNavigation/ContentSideNavigation'

export default {
  components: {
    ContentSideNavigation
  }
}
</script>

<style>

</style>