<template>
  <div class="home-content-container" style="padding-top: 64px">
      <SideNavigation>
        <SideTab :to="{ name: 'home.company.location' }">Standorte</SideTab>
        <SideTab :to="{ name: 'home.company.employee' }">Mitarbeiter</SideTab>
        <SideTab :to="{ name: 'home.company.settings' }">Einstellungen</SideTab>
      </SideNavigation>
      <div class="home-content-main">
          <router-view></router-view>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideNavigation from '@/components/General/SideNavigation/SideNavigation.vue'
import SideTab from '@/components/General/SideNavigation/SideTab.vue'

export default {
  name: 'Home',
  components: {
    SideNavigation,
    SideTab
  }
}
</script>
