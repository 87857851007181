<template>
  <div class="home-content-container" style="padding-top: 64px">
      <SideNavigation>
        <SideTab :to="{ name: 'home.infrastructure.vm' }">Virtuelle Maschinen</SideTab>
        <SideTab :to="{ name: 'home.infrastructure.network' }">Netzwerk</SideTab>
        <SideTab :to="{ name: 'home.infrastructure.client' }">Clients</SideTab>
        <SideTab v-if="false" :to="{ name: 'home.infrastructure.printer' }">Drucker</SideTab>
        <SideTab v-if="false" :to="{ name: 'home.infrastructure.other' }">Sonstiges</SideTab>
      </SideNavigation>
      <div class="home-content-main">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideNavigation from '@/components/General/SideNavigation/SideNavigation.vue'
import SideTab from '@/components/General/SideNavigation/SideTab.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  components: {
    SideNavigation,
    SideTab
  },
  methods: {
    ...mapActions('infrastructure_vm', ['loadVmsState']),
    ...mapActions('infrastructure_vm_state', ['loadStates']),
    ...mapActions(['setLoadingPercentage'])
  },
  async mounted () {
    setInterval(async() => {
      await this.loadVmsState()
    }, 5000)

    this.setLoadingPercentage({ key: 'infrastructure_load_states', percentage: 30 })
    await this.loadStates()
    this.setLoadingPercentage({ key: 'infrastructure_load_states', percentage: 100 })
    this.setLoadingPercentage({ key: 'infrastructure_load_states', percentage: -1 })

    //load VmsState
  }
}
</script>
