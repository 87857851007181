<template>
  <div>
    <CreateClient v-model="client"></CreateClient>
    <div class="create-vm-button">
      <Button icon="plus" @click="createClicked">Client erstellen</Button>
    </div>
  </div>
</template>

<script>
import CreateClient from '@/components/Infrastructure/Client/CreateClient'
import Button from '@/components/General/Button'
import { mapActions } from 'vuex'

export default {
  components: {
    CreateClient,
    Button
  },
  data () {
    return {
      client: null
    }
  },
  methods: {
    ...mapActions('infrastructure_client', ['createClient']),
    async createClicked () {
      if (this.client.name === '' || this.client.region === null || this.vm_size === null || this.client_type === null) {
        alert('Bitte alle Optionen auswählen')
        return
      }
      
      var client = await this.createClient(this.client)
      this.$router.push({
        name: 'home.infrastructure.client.show',
        params: {
          id: client.id
        }
      })
    }
  }
}
</script>

<style>

</style>