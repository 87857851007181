<template>
  <div>
    <ContentHeaderNavigation v-model="data" buttonText="Neues Netzwerk" buttonIcon="plus" toggleText="Gruppenansicht" @addClicked="newNetwork" />
    
    <CardContainer :addable="true" addText="Neue Gruppe" @addClicked="newNetwork">
      <Card v-for="network in displayNetworks" :key="network.id" @click="cardClicked(network.id)">
        {{ network.name }}
      </Card>
    </CardContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContentHeaderNavigation from '@/components/General/OtherNavigation/ContentHeaderNavigation'
import Card from '@/components/General/Card/Card'
import CardContainer from '@/components/General/Card/CardContainer'

export default {
  data () {
    return {
      data: {
        searchData: '',
        toggleData: false
      }
    }
  },
  components: {
    ContentHeaderNavigation,
    Card,
    CardContainer
  },
  methods: {
    ...mapActions('infrastructure_network', ['loadTenantNetworks']),
    ...mapActions(['setLoadingPercentage']),
    cardClicked (id) {
      this.$router.push({ name: 'home.infrastructure.network.show', params: { id } })
    },
    newNetwork () {
      this.$router.push({
        name: 'home.infrastructure.network.create'
      })
    }
  },
  computed: {
    ...mapState('infrastructure_network', ['networks']),
    displayNetworks () {
      if (this.data.searchData && this.data.searchData.length > 0) {
        var toSearch = this.data.searchData.toLowerCase()
        return this.networks.filter(x => x.name.toLowerCase().indexOf(toSearch) >= 0)
      } else {
        return this.networks
      }
      
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: 30 })
    await this.loadTenantNetworks()
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: 100 })
    this.setLoadingPercentage({ key: 'infrastructure_network_load_networks', percentage: -1 })
  }
}
</script>
