<template>
  <div class="choose-region-container">
    <div class="choose-region-heading">
      Standort auswählen
    </div>
    <select v-model="selectedLocation">
      <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    locations: Array,
    value: String
  },
  data () {
    return {
      selectedLocation: null
    }
  },
  watch: {
    value: function () {
      this.selectedLocation = this.value
    },
    selectedLocation () {
      this.$emit('input', this.selectedLocation)
    }
  }
}
</script>

<style>

</style>