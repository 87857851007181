<template>
  <router-link :to="to" class="side-nav-tab">
    <slot></slot>
    <div class="side-nav-tab-slider"></div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: Object
  }
}
</script>

<style>

</style>