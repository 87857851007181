<template>
  <div>
    <TitleNavigation backText="Zurück zu virtuelle Maschinen" :title="vm.name" :path="{ name: 'home.infrastructure.vm.list' }">
      <div :style="power_style(vm.state)"></div>
      <div style="background: #eeeeee; font-size: 10px; display: inline-block; margin-left: 20px;">{{ getStateDisplay(vm.state) }}</div>
    </TitleNavigation>
    <div style="display: flex">
      <Button @click="powerBtnClicked" :disabled="isWaiting">{{ buttonText }}</Button>
      <Button style="margin-left: 10px;" @click="spiceBtnClicked" >Spice Download</Button>
    </div>
    
    <div class="network-content-container">
      <ContentSideNavigation>
        <router-link :to="{ name: 'home.infrastructure.vm.show.overview' }">Übersicht</router-link>
        <router-link :to="{ name: 'home.infrastructure.vm.show.console' }">Konsole</router-link>
        <router-link :to="{ name: 'home.infrastructure.vm.show.graphs' }">Graphen</router-link>
        <router-link :to="{ name: 'home.infrastructure.vm.show.settings' }">Einstellungen</router-link>
        <router-link v-if="!isClient" :to="{ name: 'home.infrastructure.vm.show.delete' }">Löschen</router-link>
      </ContentSideNavigation>
      <transition name="fade" mode="out-in">
        <router-view class="network-content-container-router-view" :vm="vm"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleNavigation from '@/components/General/OtherNavigation/TitleNavigation'
import ContentSideNavigation from '@/components/General/OtherNavigation/ContentSideNavigation'
import Button from '@/components/General/Button'

export default {
  components: {
    TitleNavigation,
    ContentSideNavigation,
    Button
  },
  data () {
    return {
      isWaiting: false
    }
  },
  beforeDestroy () {
    this.addVmsToStateLoad({ vms: [ this.vm.id ], load: false })
  },
  methods: {
    ...mapActions('infrastructure_vm', ['loadVm', 'addVmsToStateLoad']),
    ...mapActions(['setLoadingPercentage', 'wsSendAndWait']),
    async sendPowerCmd (powerOn) {
      var action = powerOn ? 'vm_powerOn' : 'vm_powerOff'
      await this.wsSendAndWait({
        type: 'cmd',
        action: action,
        data: {
          vmId: this.vm.id
        }
      })
      //ToDo: Wait for result and for updated state. Maybe also give an error if not succeeded
    },
    powerBtnClicked () {
      this.isWaiting = true
      this.sendPowerCmd(this.vm.state === 'e83353fb-cf23-48de-ba9b-0a4de19bfca0')
    },
    async spiceBtnClicked () {
      var data = await this.wsSendAndWait({
        type: 'cmd',
        action: 'vm_startSpice',
        data: {
          vmId: this.vm.id
        }
      })
      console.log(data)

      var lines = [
        '[virt-viewer]',
        ...data.data.lines
      ]

      /*var index = lines.findIndex(x => x.indexOf('proxy=') >= 0)
      if (index >= 0) {
        lines[index] = 'proxy=http://127.0.0.1:5555'
      }*/

      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(lines.join('\n')));
      element.setAttribute('download', 'spice.vv');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  },
  computed: {
    ...mapGetters('infrastructure_vm', ['vmById', 'vmIsClient']),
    ...mapGetters('infrastructure_vm_state', ['getStateDisplay', 'getStateColor']),
    vmId () {
      return this.$route.params.id
    },
    vm () {
      return this.vmById(this.vmId)
    },
    isClient () {
      return this.vmIsClient(this.vmId)
    },
    power_style () {
      var self = this
      return (state) => {
        var base = {
          width: '15px',
          height: '15px',
          'border-radius': '50%',
          display: 'inline-block'
        }
        if(!self.isWaiting) {
          var color = this.getStateColor(state)
          base.background = color
        } else {
          base.background = this.getStateColor('b5d17141-b740-4f40-a26c-6f9c962d05a3')
          base.animation = "pulse 1s infinite ease-in-out"
          console.log('isWaiting')
        } 
        return base       
      }
    },
    buttonText () {
      if (this.vm.state === 'e83353fb-cf23-48de-ba9b-0a4de19bfca0') {
        return 'Start'
      } else {
        return 'Stop'
      }
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'infrastructure_network_load_vm', percentage: 30 })
    await this.loadVm(this.vmId)
    this.setLoadingPercentage({ key: 'infrastructure_network_load_vm', percentage: 100 })
    this.setLoadingPercentage({ key: 'infrastructure_network_load_vm', percentage: -1 })

    this.addVmsToStateLoad({ vms: [ this.vm.id ], load: true })
  },
  watch: {
    vm: {
      deep: true,
      handler (from, to) {
        if (from.state !== to.state) {
          this.isWaiting = false
          console.log(to.state)
          console.log(from.state)
          console.log('changed')
        }
      }
    }
  }
}
</script>

<style>
  @keyframes pulse {
    0% { transform: scale(0.7); opacity: 0.7; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.7); opacity: 0.7; }
  }
</style>