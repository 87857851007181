<template>
  <div class="choose-network-container">
    <div class="choose-network-header">Netzwerk auswählen</div>
    <div :class="classes(network.id)" v-for="network in networks" :key="network.id" @click="selectedNetwork(network)">
      {{ network.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    networks: Array,
    value: Array
  },
  data () {
    return {
      selectedNetworks: []
    }
  },
  computed: {
    classes () {
      return (id) => {
        var base = [ 'choose-network-card' ]
        if (this.selectedNetworks.findIndex(x => x.id === id) >= 0) {
          base.push('active')
        }
        return base.join(' ')
      }
    },
    valueEqualsSelected () {
      return JSON.stringify(this.value) === JSON.stringify(this.selectedNetworks.map(x => x.id))
    }
  },
  methods: {
    selectedNetwork (network) {
      if (this.selectedNetworks.findIndex(x => x.id === network.id) >= 0) {
        this.selectedNetworks = this.selectedNetworks.filter(x => x.id !== network.id)
      } else {
        this.selectedNetworks.push(network)
      }
    }
  },
  watch: {
    value: function () {
      if (!this.valueEqualsSelected) {
        this.selectedNetworks = this.networks.filter(x => this.value.indexOf(x.id) >= 0)
      }
    },
    selectedNetworks: {
      deep: true,
      handler: function () {
        if (!this.valueEqualsSelected) {
          this.$emit('input', this.selectedNetworks.map(x => x.id))
        }
      }
    } 
  }
}
</script>

<style>

</style>