<template>
  <div>
    <Input placeholder="Netzwerk Name" v-model="network_name" icon=""/>
    <Button @click="deleteNetwork" style="margin-left: 20px" :disabled="buttonDisabled">Löschen</Button>
  </div>
</template>

<script>
import Input from '@/components/General/Input'
import Button from '@/components/General/Button'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Input,
    Button
  },
  data () {
    return {
      network_name: ''
    }
  },
  computed: {
    ...mapGetters('infrastructure_network', ['networkById']),
    networkId () {
      return this.$route.params.id
    },
    network () {
      return this.networkById(this.networkId)
    },
    buttonDisabled () {
      return (this.network_name !== this.network.name) || (this.network.vms.length > 0)
    }
  },
  methods: {
    ...mapActions(['setLoadingPercentage']),
    ...mapActions('infrastructure_network', ['updateNetwork']),
    async deleteNetwork () {
      if (this.network.vms.length > 0) {
        alert('Es sind noch virtuelle Maschinen mit dem Netzwerk verbunden')
      } else {
        if (this.network_name === this.network.name) {
          this.setLoadingPercentage({ key: 'infrastructure_network_delete_network', percentage: 30 })

          await this.updateNetwork({
            id: this.networkId,
            state: '8b29ca27-4b2a-469a-9a6d-29b30d2c35de'
          })
          this.network_name = ''

          this.setLoadingPercentage({ key: 'infrastructure_network_delete_network', percentage: 100 })
          this.setLoadingPercentage({ key: 'infrastructure_network_delete_network', percentage: -1 })
        } else {
          alert('Netzwerk Name korrekt angeben.')
        }
      }
    }
  }
}
</script>

<style>

</style>