<template>
  <div class="thanos-card-container">
    <slot></slot>
    <Card v-if="addable" :addable="true" @click="addClicked">{{ addText }}</Card>
  </div>
</template>

<script>
import Card from '@/components/General/Card/Card'

export default {
  props: {
    addable: Boolean,
    addText: String
  },
  components: {
    Card
  },
  methods: {
    addClicked (e) {
      this.$emit('addClicked', e)
    }
  }
}
</script>

<style>

</style>