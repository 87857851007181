<template>
  <div>
    <TitleNavigation backText="Zurück zu Clients" :title="client.name" :path="{ name: 'home.infrastructure.client.list' }"></TitleNavigation>
    <div class="network-content-container">
      <ContentSideNavigation>
        <router-link :to="{ name: 'home.infrastructure.client.show.overview' }">Übersicht</router-link>
        <router-link :to="{ name: 'home.infrastructure.client.show.settings' }">Einstellungen</router-link>
        <router-link :to="{ name: 'home.infrastructure.client.show.delete' }">Löschen</router-link>
    </ContentSideNavigation>
      <transition name="fade" mode="out-in">
        <router-view class="network-content-container-router-view" :client="client"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleNavigation from '@/components/General/OtherNavigation/TitleNavigation'
import ContentSideNavigation from '@/components/General/OtherNavigation/ContentSideNavigation'

export default {
  components: {
    TitleNavigation,
    ContentSideNavigation
  },
  methods: {
    ...mapActions('infrastructure_client', ['loadClient']),
    ...mapActions(['setLoadingPercentage']),
  },
  computed: {
    ...mapGetters('infrastructure_client', ['clientById']),
    clientId () {
      return this.$route.params.id
    },
    client () {
      return this.clientById(this.clientId)
    }
  },
  async mounted () {
    this.setLoadingPercentage({ key: 'infrastructure_network_load_client', percentage: 30 })
    await this.loadClient(this.clientId)
    this.setLoadingPercentage({ key: 'infrastructure_network_load_client', percentage: 100 })
    this.setLoadingPercentage({ key: 'infrastructure_network_load_client', percentage: -1 })
  }
}
</script>

<style>

</style>