<template>
  <div class="choose-region-container">
    <div class="choose-region-heading">
      Region auswählen
    </div>
    <div :class="classes(region.id)" v-for="region in regions" :key="region.id" @click="regionSelected(region)">
      <div class="choose-region-icon">
        <img :src="imgPath(region.icon)" />
      </div>
      <div class="choose-region-meta">
        <div class="choose-region-name">{{ region.name }}</div>
        <div class="choose-region-location">{{ region.location }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    regions: Array,
    value: Object
  },
  data () {
    return {
      selectedRegion: null
    }
  },
  computed: {
    classes () {
      return (id) => {
        var base = ['choose-region-card']
        if (this.selectedRegion !== null && this.selectedRegion.id === id) base.push('active')
        return base.join(' ')
      }
    },
    imgPath () {
      return (id) => {
        return process.env.VUE_APP_DIRECTUS_URL + '/assets/' + id
      }
    }
  },
  methods: {
    regionSelected (region) {
      this.$emit('input', region)
      this.selectedRegion = region
    }
  },
  watch: {
    value: function () {
      this.selectedRegion = this.value
    }
  }
}
</script>

<style>

</style>