<template>
  <div class="thanos-toggle">
    <slot></slot>
    <div :class="classes" @click="clicked">
      <div class="thanos-toggle-box"></div>
      <div class="thanos-toggle-bubble"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      toggleVal: false
    }
  },
  props: {
    value: Boolean
  },
  methods: {
    clicked () {
      this.toggleVal = !this.toggleVal
      this.$emit('input', this.toggleVal)
    }
  },
  computed: {
    classes () {
      var base = ['thanos-toggle-container']
      if (this.toggleVal) {
        base.push('on')
      }
      return base.join(' ')
    }
  },
  watch: {
    value () {
      this.toggleVal = this.value
    }
  }
}
</script>

<style>

</style>