<template>
  <div class="choose-image-container">
    <div class="choose-image-heading">
      Image auswählen
    </div>
    <div :class="classes(image.id)" v-for="image in images" :key="image.id" @click="imageSelected(image)">
      <div class="choose-image-icon">
        <img :src="imgPath(image.icon)" />
      </div>
      <div class="choose-image-name">{{ image.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: Array,
    value: Object
  },
  data () {
    return {
      selectedImage: null
    }
  },
  computed: {
    classes () {
      return (id) => {
        var base = ['choose-image-card']
        if (this.selectedImage !== null && this.selectedImage.id === id) base.push('active')
        return base.join(' ')
      }
    },
    imgPath () {
      return (id) => {
        return process.env.VUE_APP_DIRECTUS_URL + '/assets/' + id
      }
    }
  },
  methods: {
    imageSelected (image) {
      this.$emit('input', image)
      this.selectedImage = image
    }
  },
  watch: {
    value: function () {
      this.selectedImage = this.value
    }
  }
}
</script>

<style>

</style>