<template>
  <div class="create-vm-container">
    <div class="create-vm-name">
      <div class="create-vm-name-heading">Name</div>
      <Input v-model="clientName" placeholder="Client Name" icon="tag" />
    </div>
    <ChooseRegion :regions="regions" v-model="region" />    
    <ChooseClientType :clientTypes="clientTypes" v-model="clientType" />
    <ChooseVmSize :vmSizes="vmSizes" v-model="vmSize" />
    <ChooseNetwork :networks="networks" v-model="network" />
    <ChooseEmployee :employees="employees" v-model="employee" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ChooseRegion from '@/components/General/Region/ChooseRegion'
import ChooseClientType from '@/components/General/ClientType/ChooseClientType'
import ChooseVmSize from '@/components/General/VmSize/ChooseVmSize'
import ChooseNetwork from '@/components/Infrastructure/Network/ChooseNetwork'
import ChooseEmployee from '@/components/Company/Employee/ChooseEmployee'
import Input from '@/components/General/Input'

export default {
  async mounted () {
    if (this.regions.length > 0) {
      this.region = this.regions[0]
    }

    if (this.vmSizes.length > 0) {
      this.vmSize = this.vmSizes[0]
    }

    if (this.clientTypes.length > 0) {
      this.clientType = this.clientTypes[0]
    }

    await this.loadRegions()
    if (this.region === null) this.region = this.regions[0]
    await this.loadVmSizes()
    if (this.vmSize === null) this.vmSize = this.vmSizes[0]
    await this.loadClientTypes()
    if (this.clientType === null) this.clientType = this.clientTypes[0]
    await this.loadTenantNetworks()
    await this.loadTenantEmployees()
    this.employee = localStorage.getItem('current_employee')
  },
  props: {
    value: Object
  },
  data () {
    return {
      clientName: '',
      region: null,
      vmSize: null,
      network: null,
      clientType: null,
      employee: null
    }
  },
  components: {
    ChooseRegion,
    ChooseClientType,
    ChooseEmployee,
    ChooseVmSize,
    Input,
    ChooseNetwork
  },
  computed: {
    ...mapState('general_region', ['regions']),
    ...mapState('general_vmSize', ['vmSizes']),
    ...mapState('infrastructure_clientType', ['clientTypes']),
    ...mapState('infrastructure_network', ['networks']),
    ...mapState('company_employee', ['employees']),
  },
  methods: {
    ...mapActions('general_region', ['loadRegions']),
    ...mapActions('general_vmSize', ['loadVmSizes']),
    ...mapActions('infrastructure_clientType', ['loadClientTypes']),
    ...mapActions('infrastructure_network', ['loadTenantNetworks']),
    ...mapActions('company_employee', ['loadTenantEmployees']),
    emitValue () {
      if (this.region !== null && this.vmSize !== null && this.clientType !== null) {
        var toNet = this.network
        if (toNet === null) toNet = []

        this.$emit('input', {
          name: this.clientName,
          region: this.region.id,
          client_type: this.clientType.id,
          vm_size: this.vmSize.id,
          networks: toNet,
          employee: this.employee
        })
      } 
    }
  },
  watch: {
    vmName: function () { this.emitValue() },
    region: function () { this.emitValue() },
    vmSize: function () { this.emitValue() },
    network: function () { this.emitValue() },
    employee: function () { this.emitValue() },
    value: function () {
      if (this.value.region !== this.region.id) {
        this.region = this.regions.filter(x => x.id === this.value.region)
      }

      if (this.value.vm_size !== this.vmSize.id) {
        this.vmSize = this.vmSizes.filter(x => x.id === this.value.vm_size)
      }

      if (this.name !== this.value.name) {
        this.name = this.value.name
      }

      if (this.value.client_type !== this.clientType.id) {
        this.clientType = this.clientTypes.filter(x => x.id === this.value.client_type)
      }

      if (this.value.employee !== this.employee) {
        this.employee = this.value.employee
      }

      if (JSON.stringify(this.network) !== JSON.stringify(this.value.networks)) {
        this.network = this.value.networks
      }
    }
  }
}
</script>

<style>

</style>