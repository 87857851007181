<template>
  <div class="title-navigation-container">
    <div class="title-navigation-back" @click="goBack">
      <font-awesome-icon icon="arrow-left" class="icon" />{{ backText }}
    </div>
    <div class="title-navigation-title">
      {{ title }}
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backText: String,
    title: String,
    path: Object
  },
  methods: {
    goBack () {
      if (this.back !== null) {
        this.$router.push(this.path)
      }
    }
  }
}
</script>

<style>

</style>